<template>
  <v-container fluid>
    <FilterDrawer
      current-component="users-filter"
      @filter="filterUsers"
      @clear="clearFilter"
    />

    <Table
      :data="dataTable"
      :total-pages="dataTable.totalPages"
      :total-register="dataTable.totalRegister"
      @setCurrentPage="setCurrentPage"
      @search="search"
    >
      <template v-slot:tbody="{ item }">
        <td class="text-center">
          <ImageAvatar
            :img_src="item.image_profile"
            default-icon="fas fa-user"
          />
        </td>
        <td>{{ item.name }}</td>
        <td class="text-center">
          <StatusChip :current-status="item.status" />
        </td>
        <td class="text-center">
          {{ item.email }}
        </td>
        <td class="text-center">
          {{ item.contact }}
        </td>
        <td class="text-center">
          {{ profiles.find((profile) => profile.value === item.type).type }}
        </td>
      </template>
    </Table>
  </v-container>
</template>

<script>
import { contextMenu, massAction } from '@/main.js'
import Table from '@/components/data-table/Table'
import StatusChip from '@/components/data-table/StatusChip'
import ImageAvatar from '@/components/data-table/ImageAvatar'
import FilterDrawer from '@/components/FilterDrawer'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'

export default {
  components: {
    Table,
    StatusChip,
    FilterDrawer,
    ImageAvatar,
  },
  mixins: [checkProfileRoutePermission],
  data: () => ({
    currentSearch: '',
    dataTable: {
      title: 'Usuários',
      new: 'users-new',
      edit: 'users-edit',
      context: 'users-context',
      massAction: 'users-mass-action',
      totalPages: 1,
      totalRegister: 1,
      headerButtons: {
        filter: true,
        new: true,
      },
      items: [],
      isLoading: true,
      headers: [
        {
          text: 'Imagem',
          value: 'image_profile',
          align: 'center',
          sortable: false,
          width: 50,
        },
        { text: 'Nome', value: 'name' },
        { text: 'Status', value: 'status', align: 'center' },
        { text: 'Email', value: 'email', align: 'center' },
        { text: 'Telefone', value: 'contact', align: 'center' },
        { text: 'Perfil', value: 'profile_type', align: 'center' },
      ],
    },
  }),
  computed: {
    profiles() {
      return [
      { type: 'Master', value: 'master' },
      { type: 'Administrador', value: 'admin' },
      { type: 'Editor de Arte', value: 'editor' },
      { type: 'Produção', value: 'production' },
    ]
    },
  },
  mounted() {
    this.getUsers()

    contextMenu.$once('action', (type) => {
      if (type === 'users') {
        this.getUsers()
      }
    })
    massAction.$once('action', () => {
      this.getUsers()
    })
  },
  methods: {
    setCurrentPage(page) {
      this.getUsers(page)
    },
    search(search) {
      this.currentSearch = search
      this.getUsers()
    },
    getUsers(page = 1) {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.$api
        .get('people/type/users', {
          params: {
            page,
            search: this.currentSearch,
          },
        },{
          headers: {
            Authorization: `Beader ${this.$cookies.get('token')}`,
          },
        })
        .then((res) => {
          this.dataTable.items = res.data.data
          this.dataTable.totalRegister = res.data.total
          this.dataTable.totalPages = res.data.last_page
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    filterUsers(obj) {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.$api
        .post('people/filter/user', obj)
        .then((res) => {
          this.dataTable.items = res.data.data
        })
        .catch(() => {
          this.dataTable.items = []
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    clearFilter() {
      this.getUsers()
    },
  },
}
</script>
